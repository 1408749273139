"use client";
import { useEffect } from "react";
import { Loader } from "@/components/ui/loader";
import Box from "@/components/ui/box";
import qs from "query-string";
import { env } from "@/env";
import { useSearchParams } from "next/navigation";

export default function Page() {
  const searchParams = useSearchParams();

  useEffect(() => {
    const callbackUrl = searchParams.get("callbackUrl");
    if (searchParams.has("error") && searchParams.get("error")?.toLowerCase() === "callback") {
      window.location.href = `${window.location.protocol}//${env.NEXT_PUBLIC_AUTH_DOMAIN}/login`;
      return;
    } else {
      const state = qs.stringify(
        { callbackUrl: callbackUrl || "/home" },
        { skipEmptyString: true, skipNull: true }
      );
      const query: Record<string, string | undefined> = {
        next:
          env.NEXT_PUBLIC_AUTH_DOMAIN === window.location.host
            ? undefined
            : `${window.location.origin}/auth/callback`,
        state: btoa(state)
      };
      searchParams.forEach((value, key) => (query[key] = value));
      delete query.callbackUrl;
      const url = qs.stringifyUrl(
        { url: `${window.location.protocol}//${env.NEXT_PUBLIC_AUTH_DOMAIN}`, query },
        { skipEmptyString: true, skipNull: true }
      );
      window.location.href = url;
      return;
    }
  }, [searchParams]);

  return (
    <div className="flex min-h-svh h-full flex-col items-center justify-center p-4">
      <Box className="w-[568px] p-4xl max-w-full">
        <div className="flex justify-center mb-2xl">
          <img
            src="/static/images/full-logo-black.png"
            className="object-contain rounded-small w-auto max-h-[60px]"
            alt="Key.ai"
          />
        </div>

        <div className="flex justify-center">
          <Loader />
        </div>
      </Box>
    </div>
  );
}
